import {useState, useEffect} from "react";

function RankSelector({playerId, sendSelectionToParent}){
    const [rankSelection, setRankSelection] = useState(NaN);

    useEffect(updatePlayerRankScore, [rankSelection]);

    function onRankSelectionChange(e){
        setRankSelection(e.target.value);
    };

    function updatePlayerRankScore(){
        sendSelectionToParent(playerId, parseInt(rankSelection));
    };

    return (
        <>
            <div className="selector-collection">
                <select onChange={onRankSelectionChange}>
                    <option value="NaN">Rank</option>
                    <option value="1">Iron 1</option>
                    <option value="2">Iron 2</option>
                    <option value="3">Iron 3</option>
                    <option value="4">Bronze 1</option>
                    <option value="5">Bronze 2</option>
                    <option value="6">Bronze 3</option>
                    <option value="7">Silver 1</option>
                    <option value="8">Silver 2</option>
                    <option value="9">Silver 3</option>
                    <option value="10">Gold 1</option>
                    <option value="11">Gold 2</option>
                    <option value="12">Gold 3</option>
                    <option value="13">Platinum 1</option>
                    <option value="14">Platinum 2</option>
                    <option value="15">Platinum 3</option>
                    <option value="16">Diamond 1</option>
                    <option value="17">Diamond 2</option>
                    <option value="18">Diamond 3</option>
                    <option value="19">Ascendant 1</option>
                    <option value="20">Ascendant 2</option>
                    <option value="21">Ascendant 3</option>
                    <option value="22">Immortal 1</option>
                    <option value="23">Immortal 2</option>
                    <option value="24">Immortal 3</option>
                    <option value="25">Radiant</option>
                </select>
            </div>
        </>
    );
}

export default RankSelector;