import './App.css';
import {useState} from "react";
import RankSelector from './RankSelector';

function countKnownRanks(rankScores){
  let count = 0;
  rankScores.forEach(rankScore => {
    if(rankScore)
      count ++;
  })
  return count;
}
function getRankScoreSum(rankScores){
  let sum = 0;
  rankScores.forEach(rankScore => {
    if(rankScore)
      sum += rankScore;
  })
  return sum;
}
function getAverageRankScore(rankScores){
  let sum = getRankScoreSum(rankScores);
  let count = countKnownRanks(rankScores);
  if(count)
    return sum/count;
  else
    return 0;
}
function getRankFromScore(rankScore){
  const rankNames = ["Iron", "Bronze", "Silver", "Gold", "Platinum", "Diamond", "Ascendant", "Immortal", "Radiant"];
  const rankName = rankNames[Math.floor((rankScore-1)/3)];
  const rankTier = rankName === "Radiant"? "" : " " + (((rankScore-1) % 3) + 1);
  if(!rankName)
    return undefined;
  return rankName + rankTier
}
function getUnknownEnemyAvgRank(rankScores){
  const yourTeamTotalRankScore = getRankScoreSum(rankScores.slice(0,5)) * 5 / countKnownRanks(rankScores.slice(0,5));
  const enemyTeamTotalRankScore = getRankScoreSum(rankScores.slice(5));
  const enemyTeamTotalUnknownRanks = 5 - countKnownRanks(rankScores.slice(5))
  const retVal = (yourTeamTotalRankScore - enemyTeamTotalRankScore) / enemyTeamTotalUnknownRanks;
  if(enemyTeamTotalUnknownRanks === 0)
    return undefined;
  if(1 <= retVal && retVal <= 25)
    return getRankFromScore(Math.floor(retVal+0.5));
  if (retVal < 1)
    return "Your team's average rank should be too low for this matchup to occur"
  if (retVal > 25)
    return "Your team's average rank should be too high for this matchup to occur"
}

function App() {
  const [rankScores, setRankScores] = useState([NaN,NaN,NaN,NaN,NaN,NaN,NaN,NaN,NaN,NaN]);
  function updateRankScoreFromSelector(playerId, rankSelection){
    const updatedScores = [...rankScores];
    updatedScores[playerId] = rankSelection;
    setRankScores(updatedScores);
  }

  return (
    <>
      <h1>Valorant Enemy Ranks Calculator</h1>
      <main>
        <div className="team-container self">
          <h1>Your team</h1>
          {Array.from({ length: 5 }, (_, index) => (<RankSelector playerId={index} key={index} sendSelectionToParent={updateRankScoreFromSelector}/>))}

          <h2>Your team rank average:</h2>
          {getRankFromScore(getAverageRankScore(rankScores.slice(0,5)))? getRankFromScore(Math.floor(getAverageRankScore(rankScores.slice(0,5))+0.5)): "Enter your team's ranks"}
        </div>
        <div className="team-container enemy">
          <h1>Enemy team</h1>
          {Array.from({ length: 5 }, (_, index) => (<RankSelector playerId={index + 5} key={index} sendSelectionToParent={updateRankScoreFromSelector}/>))}

          <h2>Enemy team known rank average:</h2>
          {getRankFromScore(getAverageRankScore(rankScores.slice(5)))? getRankFromScore(Math.floor(getAverageRankScore(rankScores.slice(5))+0.5)): "Enter your enemy team's ranks"}

          {getUnknownEnemyAvgRank(rankScores)?<h2>Enemy team hidden rank average:</h2>:<></>}
          {getUnknownEnemyAvgRank(rankScores)?getUnknownEnemyAvgRank(rankScores):<></>}
          
        </div>
      </main>
    </>
  );
}

export default App;
